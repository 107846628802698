import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// import React from 'react';
// import { hydrate, render } from 'react-dom';
// import { createRoot } from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';

// // const rootElement = createRoot(document.getElementById('root'));

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// if (root.hasChildNodes()) {
//   console.log('a');
//   hydrate(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     root
//   );
// } else {
//   console.log('b');
//   render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.StrictMode>,

//     root
//   );
// }
